import React, { HTMLProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import { deepFind } from '../../../utils/deep-find';
import { Loader } from '../loader';

import {
  multiSelectComponents,
  selectComponents,
  selectComponentsStyles,
} from './select-components';

export type SelectOption = {
  value: string;
  label?: string;
  description?: string;
};

export interface SelectProps
  extends Omit<HTMLProps<HTMLSelectElement>, 'name' | 'onChange' | 'onBlur'> {
  name: string;
  loading?: boolean;
  options: SelectOption[];
  required?: boolean;
  isMulti?: boolean;
  isGrouped?: boolean;
  onChange?: (newValue?: SelectOption | SelectOption[]) => void;
  onBlur?: () => void;
}

export const Select: React.FC<SelectProps> = ({
  className,
  options,
  loading,
  isMulti,
  isGrouped,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  required,
  placeholder,
  ...props
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('FormInput must be inside Form Component');
  }
  const hasError = deepFind(ctx.formState.errors, props.name);

  const components = React.useMemo(() => {
    const components = isMulti ? multiSelectComponents : selectComponents;

    return {
      ...components,
      Control: (props: any) => (
        <components.Control
          {...props}
          hasError={hasError}
          required={required}
        />
      ),
    };
  }, [hasError, isMulti]);

  const handleOnChange = React.useCallback(
    (onChangeFunc, newValue, actions) => {
      if (onChangeProp) onChangeProp(newValue);

      isMulti ? onChangeFunc(newValue, actions) : onChangeFunc(newValue?.value);
    },
    [onChangeProp, isMulti]
  );

  const handleOnBlur = React.useCallback(
    (blurFormFunc: (...event: any[]) => void) => () => {
      blurFormFunc();

      if (onBlurProp) {
        onBlurProp();
      }
    },
    [onBlurProp]
  );

  return (
    <Controller
      control={ctx.control}
      name={props.name}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <ReactSelect
          components={components}
          styles={selectComponentsStyles}
          name={props.name}
          options={options}
          onBlur={handleOnBlur(onBlur)}
          value={
            (isMulti
              ? value.map((v: SelectOption) =>
                  options.find((o: SelectOption) => o.value === v.value)
                )
              : isGrouped
              ? options
                  .reduce(
                    (prev: any[], curr: any) => [
                      ...prev,
                      ...(curr.options || []),
                    ],
                    []
                  )
                  .find((o: SelectOption) => o.value === value)
              : options.find((o: SelectOption) => o.value === value)) || ''
          }
          onChange={(...args) => handleOnChange(onChange, ...args)}
          ref={ref}
          isMulti={isMulti}
          hideSelectedOptions={isMulti}
          blurInputOnSelect={false}
          backspaceRemovesValue
          isSearchable
          menuPosition="fixed"
          placeholder={
            loading ? (
              <Loader className="inline w-4 h-4 mr-3 fill-white absolute animate-spin bottom-3 left-4 text-slate-300" />
            ) : (
              placeholder || 'Pick one'
            )
          }
          isDisabled={loading || props.disabled}
        />
      )}
    />
  );
};
