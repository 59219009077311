import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { countries } from '../../config/const/countries.const';
import { GroupQuery } from '../../types';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';
import { Button } from '../shared/button/button';
import { Input, MultiLineInput } from '../shared/input/input';
import { RadioGroup } from '../shared/radio-group/radio-group';
import { BrregSearchInput } from '../shared/search-input/brreg-search-input';
import { Select } from '../shared/select/select';

interface AddressProps {
  address1?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
}

type ClientType = 'company' | 'person';

interface ContactFormProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  description: string;
  topic?: number;
  clientType: ClientType;
  address: AddressProps;
  company: {
    name: string;
    orgNumber: string;
    companyType: string;
    industryCode?: string[];
    sectorCode: string;
  };
}

export const query = graphql`
  query {
    allPrismicContactPage {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            legal_advice_section_title
            legal_advice_form_description

            legal_advice_phone_number_placeholder
            legal_advice_email_input_placeholder
            firstname_input_placeholder
            lastname_input_placeholder

            company_radio_text
            person_radio_text
            company_data_title
            company_search_input_placeholder
            company_name_input_placeholder
            company_orgnumber_input_placeholder
            company_type_input_placeholder
            company_address_input_placeholder
            company_city_input_placeholder
            company_postalcode_input_placeholder
            company_country_input_placeholder

            case_data_title
            legal_advice_case_category_select_placeholder
            legal_advice_case_description_input_placeholder

            legal_advice_button_label
            seek_legal_advice_success_message
            seek_legal_advice_error_message
            required_field_label
            phone_number_field_error
            email_field_error

            topics_list_error
            required_fields_error
            company_brreg_error
            company_brreg_recent_label
            company_brreg_noresults_label

            address_data_title
          }
        }
      }
    }
  }
`;

interface LegalAdviceData {
  legal_advice_section_title: string;
  legal_advice_form_description: string;

  firstname_input_placeholder: string;
  lastname_input_placeholder: string;
  legal_advice_email_input_placeholder: string;
  legal_advice_phone_number_placeholder: string;
  company_radio_text: string;
  person_radio_text: string;
  company_data_title: string;
  company_search_input_placeholder: string;
  company_name_input_placeholder: string;
  company_orgnumber_input_placeholder: string;
  company_type_input_placeholder: string;
  company_address_input_placeholder: string;
  company_city_input_placeholder: string;
  company_postalcode_input_placeholder: string;
  company_country_input_placeholder: string;
  case_data_title: string;
  legal_advice_case_category_select_placeholder: string;
  legal_advice_case_description_input_placeholder: string;

  legal_advice_button_label: string;
  seek_legal_advice_success_message: string;
  seek_legal_advice_error_message: string;
  required_field_label: string;
  phone_number_field_error: string;
  email_field_error: string;

  topics_list_error: string;
  required_fields_error: string;
  company_brreg_error: string;
  company_brreg_recent_label: string;
  company_brreg_noresults_label: string;

  address_data_title: string;
}

interface LegalAdviceQuery {
  allPrismicContactPage: GroupQuery<LegalAdviceData>;
}

export interface SearchInputOption {
  id: string | number;
  name: string;
  description: string;
}

export const SeekLegalAdvice = () => {
  const data = useStaticQuery<LegalAdviceQuery>(query);
  const [isLoading, setIsLoading] = useState(false);
  const [topics, setTopics] = useState([]);

  const legalAdviceData = getCurrentLanguageData(
    data.allPrismicContactPage.group
  );

  const countriesList = useMemo(
    () =>
      countries.map(country => ({
        label: country.name,
        value: country.code,
      })),
    []
  );

  const clientTypeList = useMemo(
    () => [
      {
        value: 'company',
        label: legalAdviceData.company_radio_text,
      },
      {
        value: 'person',
        label: legalAdviceData.person_radio_text,
      },
    ],
    []
  );

  const form = useForm<ContactFormProps>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      description: '',
      topic: undefined,
      clientType: 'company',
      address: {
        address1: '',
        postalCode: '',
        city: '',
        countryCode: 'NO',
      },
      company: {
        name: '',
        orgNumber: '',
        companyType: '',
        industryCode: [],
        sectorCode: '',
      },
    },
  });

  const clientType = form.watch('clientType');

  const onSubmit = async (data: ContactFormProps) => {
    setIsLoading(true);
    try {
      await axios.post(`${process.env.GATSBY_API_URL}/seekLegalAdviceForm`, {
        ...data,
        isPrivate: data.clientType === 'person',
      });

      toast.success(legalAdviceData.seek_legal_advice_success_message);
      form.reset();
    } catch (err) {
      toast.error(legalAdviceData.seek_legal_advice_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopics = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.GATSBY_API_URL}/topicsList`
      );

      setTopics(
        data.data.map(topic => ({
          label: topic.name,
          value: topic.id,
        }))
      );
    } catch {
      toast.error(legalAdviceData.topics_list_error);
    }
  }, []);

  useEffect(() => {
    fetchTopics();
  }, []);

  const onError = useCallback(err => {
    if (err.email?.type === 'pattern') {
      toast.error(legalAdviceData.email_field_error);
    } else if (err.phoneNumber?.type === 'pattern') {
      toast.error(legalAdviceData.phone_number_field_error);
    } else toast.error(legalAdviceData.required_fields_error);
  }, []);

  const handleClientTypeChange = useCallback(() => {
    form.setValue('company.name', '');
    form.setValue('company.companyType', '');
    form.setValue('company.orgNumber', '');
    form.setValue('company.industryCode', []);
    form.setValue('company.sectorCode', '');
    form.setValue('address.city', '');
    form.setValue('address.countryCode', 'NO');
    form.setValue('address.postalCode', '');
    form.setValue('address.address1', '');
  }, [form]);

  const onApplySearch = useCallback(
    option => {
      form.setValue('company.name', option.name);
      form.setValue('company.companyType', option.type);
      form.setValue('address.city', option.address.city);
      form.setValue('address.countryCode', option.address.countryCode);
      form.setValue('address.postalCode', option.address.postalCode);
      form.setValue('address.address1', option.address.address1);
      form.setValue('company.orgNumber', option.id);
      if (option.industryCode?.length) {
        form.setValue('company.industryCode', option.industryCode);
      }
      if (option.sectorCode) {
        form.setValue('company.sectorCode', option.sectorCode);
      }
    },
    [form]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onError)}>
        <h2 className="label-m text-ecit-blue">
          {legalAdviceData.legal_advice_section_title}
        </h2>
        <p className="paragraph font-bold text-ecit-green md:pr-24 mt-4 mb-16">
          {legalAdviceData.legal_advice_form_description}
        </p>
        <div className="grid md:grid-cols-2 md:gap-x-32 gap-y-9">
          <Input
            required
            full
            disabled={isLoading}
            placeholder={legalAdviceData.firstname_input_placeholder}
            {...form.register('firstName', { required: true })}
          />
          <Input
            required
            full
            disabled={isLoading}
            placeholder={legalAdviceData.lastname_input_placeholder}
            {...form.register('lastName', { required: true })}
          />
          <Input
            required
            full
            disabled={isLoading}
            placeholder={legalAdviceData.legal_advice_email_input_placeholder}
            {...form.register('email', {
              required: true,
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            })}
          />
          <Input
            required
            full
            disabled={isLoading}
            placeholder={legalAdviceData.legal_advice_phone_number_placeholder}
            {...form.register('phoneNumber', {
              required: true,
              pattern: /^[\+]?[0-9\-\s]{7,20}$/,
            })}
          />
        </div>
        <div className="mt-16 flex flex-col gap-y-9">
          <RadioGroup
            className="md:gap-x-32"
            name="clientType"
            options={clientTypeList}
            horizontal
            disabled={isLoading}
            onChange={handleClientTypeChange}
          />
        </div>

        <div className="mt-16 flex flex-col gap-y-9">
          {clientType === 'company' ? (
            <>
              <p className="paragraph font-bold text-ecit-green">
                {legalAdviceData.company_data_title}
              </p>

              <BrregSearchInput
                onApplySearch={onApplySearch}
                disabled={isLoading}
                recentText={legalAdviceData.company_brreg_recent_label}
                noResultsText={legalAdviceData.company_brreg_noresults_label}
                errorText={legalAdviceData.company_brreg_error}
                placeholder={legalAdviceData.company_search_input_placeholder}
              />
              <Input
                required
                full
                placeholder={legalAdviceData.company_name_input_placeholder}
                {...form.register('company.name', {
                  required: clientType === 'company',
                })}
                disabled={isLoading}
              />
              <div className="grid md:grid-cols-2 md:gap-x-32 gap-y-9">
                <Input
                  required
                  full
                  placeholder={
                    legalAdviceData.company_orgnumber_input_placeholder
                  }
                  {...form.register('company.orgNumber', {
                    required: clientType === 'company',
                  })}
                  disabled={isLoading}
                />
                <Input
                  required
                  full
                  placeholder={legalAdviceData.company_type_input_placeholder}
                  {...form.register('company.companyType', {
                    required: clientType === 'company',
                  })}
                  disabled={isLoading}
                />
              </div>
            </>
          ) : (
            <p className="paragraph font-bold text-ecit-green">
              {legalAdviceData.address_data_title}
            </p>
          )}
          <div className="grid md:grid-cols-2 md:gap-x-32 gap-y-9">
            <Input
              required
              full
              placeholder={legalAdviceData.company_address_input_placeholder}
              {...form.register('address.address1', {
                required: true,
              })}
              disabled={isLoading}
            />
            <Input
              required
              full
              placeholder={legalAdviceData.company_city_input_placeholder}
              {...form.register('address.city', {
                required: true,
              })}
              disabled={isLoading}
            />
            <Input
              required
              full
              placeholder={legalAdviceData.company_postalcode_input_placeholder}
              {...form.register('address.postalCode', {
                required: true,
              })}
              disabled={isLoading}
            />
            <Select
              required
              disabled={isLoading}
              name="address.countryCode"
              options={countriesList}
              placeholder={
                legalAdviceData.legal_advice_case_category_select_placeholder
              }
            />
          </div>
        </div>

        <div className="mt-16 flex flex-col gap-y-9">
          <p className="paragraph font-bold text-ecit-green">
            {legalAdviceData.case_data_title}
          </p>

          <Select
            disabled={isLoading}
            options={topics}
            placeholder={
              legalAdviceData.legal_advice_case_category_select_placeholder
            }
            name="topic"
            required
          />

          <MultiLineInput
            required
            full
            placeholder={
              legalAdviceData.legal_advice_case_description_input_placeholder
            }
            className="mb-6"
            {...form.register('description', { required: true })}
            disabled={isLoading}
          />
        </div>
        <p className="header-xs text-greyscale-mediumlight font-normal mb-8">
          {`* ${legalAdviceData.required_field_label}`}
        </p>
        <Button type="submit" variant="outline" loading={isLoading}>
          {legalAdviceData.legal_advice_button_label}
        </Button>
      </form>
    </FormProvider>
  );
};
