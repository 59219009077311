export const countries = [
  { name: 'Afghanistan', code: 'AF', region: 'Asia', currency: 'AFN' },
  { name: 'Åland Islands', code: 'AX', region: 'Europe', currency: 'EUR' },
  { name: 'Albania', code: 'AL', region: 'Europe', currency: 'ALL' },
  { name: 'Algeria', code: 'DZ', region: 'Africa', currency: 'DZD' },
  { name: 'American Samoa', code: 'AS', region: 'Oceania', currency: 'USD' },
  { name: 'Andorra', code: 'AD', region: 'Europe', currency: 'EUR' },
  { name: 'Angola', code: 'AO', region: 'Africa', currency: 'AOA' },
  { name: 'Anguilla', code: 'AI', region: 'Americas', currency: 'XCD' },
  { name: 'Antarctica', code: 'AQ', region: 'Polar', currency: '' },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    region: 'Americas',
    currency: 'XCD',
  },
  { name: 'Argentina', code: 'AR', region: 'Americas', currency: 'ARS' },
  { name: 'Armenia', code: 'AM', region: 'Asia', currency: 'AMD' },
  { name: 'Aruba', code: 'AW', region: 'Americas', currency: 'AWG' },
  { name: 'Australia', code: 'AU', region: 'Oceania', currency: 'AUD' },
  { name: 'Austria', code: 'AT', region: 'Europe', currency: 'EUR' },
  { name: 'Azerbaijan', code: 'AZ', region: 'Asia', currency: 'AZN' },
  { name: 'Bahamas', code: 'BS', region: 'Americas', currency: 'BSD' },
  { name: 'Bahrain', code: 'BH', region: 'Asia', currency: 'BHD' },
  { name: 'Bangladesh', code: 'BD', region: 'Asia', currency: 'BDT' },
  { name: 'Barbados', code: 'BB', region: 'Americas', currency: 'BBD' },
  { name: 'Belarus', code: 'BY', region: 'Europe', currency: 'BYR' },
  { name: 'Belgium', code: 'BE', region: 'Europe', currency: 'EUR' },
  { name: 'Belize', code: 'BZ', region: 'Americas', currency: 'BZD' },
  { name: 'Benin', code: 'BJ', region: 'Africa', currency: 'XOF' },
  { name: 'Bermuda', code: 'BM', region: 'Americas', currency: 'BMD' },
  { name: 'Bhutan', code: 'BT', region: 'Asia', currency: 'BTN' },
  {
    name: 'Bolivia (Plurinational State of)',
    code: 'BO',
    region: 'Americas',
    currency: 'BOB',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
    region: 'Americas',
    currency: 'USD',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    region: 'Europe',
    currency: 'BAM',
  },
  { name: 'Botswana', code: 'BW', region: 'Africa', currency: 'BWP' },
  { name: 'Bouvet Island', code: 'BV', region: '', currency: 'NOK' },
  { name: 'Brazil', code: 'BR', region: 'Americas', currency: 'BRL' },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    region: 'Africa',
    currency: 'USD',
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    region: 'Americas',
    currency: 'USD',
  },
  {
    name: 'Virgin Islands (British)',
    code: 'VG',
    region: 'Americas',
    currency: 'USD',
  },
  {
    name: 'Virgin Islands (U.S.)',
    code: 'VI',
    region: 'Americas',
    currency: 'USD',
  },
  { name: 'Brunei Darussalam', code: 'BN', region: 'Asia', currency: 'BND' },
  { name: 'Bulgaria', code: 'BG', region: 'Europe', currency: 'BGN' },
  { name: 'Burkina Faso', code: 'BF', region: 'Africa', currency: 'XOF' },
  { name: 'Burundi', code: 'BI', region: 'Africa', currency: 'BIF' },
  { name: 'Cambodia', code: 'KH', region: 'Asia', currency: 'KHR' },
  { name: 'Cameroon', code: 'CM', region: 'Africa', currency: 'XAF' },
  { name: 'Canada', code: 'CA', region: 'Americas', currency: 'CAD' },
  { name: 'Cabo Verde', code: 'CV', region: 'Africa', currency: 'CVE' },
  { name: 'Cayman Islands', code: 'KY', region: 'Americas', currency: 'KYD' },
  {
    name: 'Central African Republic',
    code: 'CF',
    region: 'Africa',
    currency: 'XAF',
  },
  { name: 'Chad', code: 'TD', region: 'Africa', currency: 'XAF' },
  { name: 'Chile', code: 'CL', region: 'Americas', currency: 'CLP' },
  { name: 'China', code: 'CN', region: 'Asia', currency: 'CNY' },
  { name: 'Christmas Island', code: 'CX', region: 'Oceania', currency: 'AUD' },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    region: 'Oceania',
    currency: 'AUD',
  },
  { name: 'Colombia', code: 'CO', region: 'Americas', currency: 'COP' },
  { name: 'Comoros', code: 'KM', region: 'Africa', currency: 'KMF' },
  { name: 'Congo', code: 'CG', region: 'Africa', currency: 'XAF' },
  {
    name: 'Congo (Democratic Republic of the)',
    code: 'CD',
    region: 'Africa',
    currency: 'CDF',
  },
  { name: 'Cook Islands', code: 'CK', region: 'Oceania', currency: 'NZD' },
  { name: 'Costa Rica', code: 'CR', region: 'Americas', currency: 'CRC' },
  { name: 'Croatia', code: 'HR', region: 'Europe', currency: 'HRK' },
  { name: 'Cuba', code: 'CU', region: 'Americas', currency: 'CUP' },
  { name: 'Curaçao', code: 'CW', region: 'Americas', currency: 'ANG' },
  { name: 'Cyprus', code: 'CY', region: 'Europe', currency: 'EUR' },
  { name: 'Czech Republic', code: 'CZ', region: 'Europe', currency: 'CZK' },
  { name: 'Denmark', code: 'DK', region: 'Europe', currency: 'DKK' },
  { name: 'Djibouti', code: 'DJ', region: 'Africa', currency: 'DJF' },
  { name: 'Dominica', code: 'DM', region: 'Americas', currency: 'XCD' },
  {
    name: 'Dominican Republic',
    code: 'DO',
    region: 'Americas',
    currency: 'DOP',
  },
  { name: 'Ecuador', code: 'EC', region: 'Americas', currency: 'USD' },
  { name: 'Egypt', code: 'EG', region: 'Africa', currency: 'EGP' },
  { name: 'El Salvador', code: 'SV', region: 'Americas', currency: 'USD' },
  { name: 'Equatorial Guinea', code: 'GQ', region: 'Africa', currency: 'XAF' },
  { name: 'Eritrea', code: 'ER', region: 'Africa', currency: 'ERN' },
  { name: 'Estonia', code: 'EE', region: 'Europe', currency: 'EUR' },
  { name: 'Ethiopia', code: 'ET', region: 'Africa', currency: 'ETB' },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    region: 'Americas',
    currency: 'FKP',
  },
  { name: 'Faroe Islands', code: 'FO', region: 'Europe', currency: 'DKK' },
  { name: 'Fiji', code: 'FJ', region: 'Oceania', currency: 'FJD' },
  { name: 'Finland', code: 'FI', region: 'Europe', currency: 'EUR' },
  { name: 'France', code: 'FR', region: 'Europe', currency: 'EUR' },
  { name: 'French Guiana', code: 'GF', region: 'Americas', currency: 'EUR' },
  { name: 'French Polynesia', code: 'PF', region: 'Oceania', currency: 'XPF' },
  {
    name: 'French Southern Territories',
    code: 'TF',
    region: 'Africa',
    currency: 'EUR',
  },
  { name: 'Gabon', code: 'GA', region: 'Africa', currency: 'XAF' },
  { name: 'Gambia', code: 'GM', region: 'Africa', currency: 'GMD' },
  { name: 'Georgia', code: 'GE', region: 'Asia', currency: 'GEL' },
  { name: 'Germany', code: 'DE', region: 'Europe', currency: 'EUR' },
  { name: 'Ghana', code: 'GH', region: 'Africa', currency: 'GHS' },
  { name: 'Gibraltar', code: 'GI', region: 'Europe', currency: 'GIP' },
  { name: 'Greece', code: 'GR', region: 'Europe', currency: 'EUR' },
  { name: 'Greenland', code: 'GL', region: 'Americas', currency: 'DKK' },
  { name: 'Grenada', code: 'GD', region: 'Americas', currency: 'XCD' },
  { name: 'Guadeloupe', code: 'GP', region: 'Americas', currency: 'EUR' },
  { name: 'Guam', code: 'GU', region: 'Oceania', currency: 'USD' },
  { name: 'Guatemala', code: 'GT', region: 'Americas', currency: 'GTQ' },
  { name: 'Guernsey', code: 'GG', region: 'Europe', currency: 'GBP' },
  { name: 'Guinea', code: 'GN', region: 'Africa', currency: 'GNF' },
  { name: 'Guinea-Bissau', code: 'GW', region: 'Africa', currency: 'XOF' },
  { name: 'Guyana', code: 'GY', region: 'Americas', currency: 'GYD' },
  { name: 'Haiti', code: 'HT', region: 'Americas', currency: 'HTG' },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
    region: '',
    currency: 'AUD',
  },
  { name: 'Holy See', code: 'VA', region: 'Europe', currency: 'EUR' },
  { name: 'Honduras', code: 'HN', region: 'Americas', currency: 'HNL' },
  { name: 'Hong Kong', code: 'HK', region: 'Asia', currency: 'HKD' },
  { name: 'Hungary', code: 'HU', region: 'Europe', currency: 'HUF' },
  { name: 'Iceland', code: 'IS', region: 'Europe', currency: 'ISK' },
  { name: 'India', code: 'IN', region: 'Asia', currency: 'INR' },
  { name: 'Indonesia', code: 'ID', region: 'Asia', currency: 'IDR' },
  { name: "Côte d'Ivoire", code: 'CI', region: 'Africa', currency: 'XOF' },
  {
    name: 'Iran (Islamic Republic of)',
    code: 'IR',
    region: 'Asia',
    currency: 'IRR',
  },
  { name: 'Iraq', code: 'IQ', region: 'Asia', currency: 'IQD' },
  { name: 'Ireland', code: 'IE', region: 'Europe', currency: 'EUR' },
  { name: 'Isle of Man', code: 'IM', region: 'Europe', currency: 'GBP' },
  { name: 'Israel', code: 'IL', region: 'Asia', currency: 'ILS' },
  { name: 'Italy', code: 'IT', region: 'Europe', currency: 'EUR' },
  { name: 'Jamaica', code: 'JM', region: 'Americas', currency: 'JMD' },
  { name: 'Japan', code: 'JP', region: 'Asia', currency: 'JPY' },
  { name: 'Jersey', code: 'JE', region: 'Europe', currency: 'GBP' },
  { name: 'Jordan', code: 'JO', region: 'Asia', currency: 'JOD' },
  { name: 'Kazakhstan', code: 'KZ', region: 'Asia', currency: 'KZT' },
  { name: 'Kenya', code: 'KE', region: 'Africa', currency: 'KES' },
  { name: 'Kiribati', code: 'KI', region: 'Oceania', currency: 'AUD' },
  { name: 'Kuwait', code: 'KW', region: 'Asia', currency: 'KWD' },
  { name: 'Kyrgyzstan', code: 'KG', region: 'Asia', currency: 'KGS' },
  {
    name: "Lao People's Democratic Republic",
    code: 'LA',
    region: 'Asia',
    currency: 'LAK',
  },
  { name: 'Latvia', code: 'LV', region: 'Europe', currency: 'EUR' },
  { name: 'Lebanon', code: 'LB', region: 'Asia', currency: 'LBP' },
  { name: 'Lesotho', code: 'LS', region: 'Africa', currency: 'LSL' },
  { name: 'Liberia', code: 'LR', region: 'Africa', currency: 'LRD' },
  { name: 'Libya', code: 'LY', region: 'Africa', currency: 'LYD' },
  { name: 'Liechtenstein', code: 'LI', region: 'Europe', currency: 'CHF' },
  { name: 'Lithuania', code: 'LT', region: 'Europe', currency: 'EUR' },
  { name: 'Luxembourg', code: 'LU', region: 'Europe', currency: 'EUR' },
  { name: 'Macao', code: 'MO', region: 'Asia', currency: 'MOP' },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    code: 'MK',
    region: 'Europe',
    currency: 'MKD',
  },
  { name: 'Madagascar', code: 'MG', region: 'Africa', currency: 'MGA' },
  { name: 'Malawi', code: 'MW', region: 'Africa', currency: 'MWK' },
  { name: 'Malaysia', code: 'MY', region: 'Asia', currency: 'MYR' },
  { name: 'Maldives', code: 'MV', region: 'Asia', currency: 'MVR' },
  { name: 'Mali', code: 'ML', region: 'Africa', currency: 'XOF' },
  { name: 'Malta', code: 'MT', region: 'Europe', currency: 'EUR' },
  { name: 'Marshall Islands', code: 'MH', region: 'Oceania', currency: 'USD' },
  { name: 'Martinique', code: 'MQ', region: 'Americas', currency: 'EUR' },
  { name: 'Mauritania', code: 'MR', region: 'Africa', currency: 'MRO' },
  { name: 'Mauritius', code: 'MU', region: 'Africa', currency: 'MUR' },
  { name: 'Mayotte', code: 'YT', region: 'Africa', currency: 'EUR' },
  { name: 'Mexico', code: 'MX', region: 'Americas', currency: 'MXN' },
  {
    name: 'Micronesia (Federated States of)',
    code: 'FM',
    region: 'Oceania',
    currency: 'USD',
  },
  {
    name: 'Moldova (Republic of)',
    code: 'MD',
    region: 'Europe',
    currency: 'MDL',
  },
  { name: 'Monaco', code: 'MC', region: 'Europe', currency: 'EUR' },
  { name: 'Mongolia', code: 'MN', region: 'Asia', currency: 'MNT' },
  { name: 'Montenegro', code: 'ME', region: 'Europe', currency: 'EUR' },
  { name: 'Montserrat', code: 'MS', region: 'Americas', currency: 'XCD' },
  { name: 'Morocco', code: 'MA', region: 'Africa', currency: 'MAD' },
  { name: 'Mozambique', code: 'MZ', region: 'Africa', currency: 'MZN' },
  { name: 'Myanmar', code: 'MM', region: 'Asia', currency: 'MMK' },
  { name: 'Namibia', code: 'NA', region: 'Africa', currency: 'NAD' },
  { name: 'Nauru', code: 'NR', region: 'Oceania', currency: 'AUD' },
  { name: 'Nepal', code: 'NP', region: 'Asia', currency: 'NPR' },
  { name: 'Netherlands', code: 'NL', region: 'Europe', currency: 'EUR' },
  { name: 'New Caledonia', code: 'NC', region: 'Oceania', currency: 'XPF' },
  { name: 'New Zealand', code: 'NZ', region: 'Oceania', currency: 'NZD' },
  { name: 'Nicaragua', code: 'NI', region: 'Americas', currency: 'NIO' },
  { name: 'Niger', code: 'NE', region: 'Africa', currency: 'XOF' },
  { name: 'Nigeria', code: 'NG', region: 'Africa', currency: 'NGN' },
  { name: 'Niue', code: 'NU', region: 'Oceania', currency: 'NZD' },
  { name: 'Norfolk Island', code: 'NF', region: 'Oceania', currency: 'AUD' },
  {
    name: "Korea (Democratic People's Republic of)",
    code: 'KP',
    region: 'Asia',
    currency: 'KPW',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    region: 'Oceania',
    currency: 'USD',
  },
  { name: 'Norway', code: 'NO', region: 'Europe', currency: 'NOK' },
  { name: 'Oman', code: 'OM', region: 'Asia', currency: 'OMR' },
  { name: 'Pakistan', code: 'PK', region: 'Asia', currency: 'PKR' },
  { name: 'Palau', code: 'PW', region: 'Oceania', currency: 'USD' },
  { name: 'Palestine, State of', code: 'PS', region: 'Asia', currency: 'ILS' },
  { name: 'Panama', code: 'PA', region: 'Americas', currency: 'PAB' },
  { name: 'Papua New Guinea', code: 'PG', region: 'Oceania', currency: 'PGK' },
  { name: 'Paraguay', code: 'PY', region: 'Americas', currency: 'PYG' },
  { name: 'Peru', code: 'PE', region: 'Americas', currency: 'PEN' },
  { name: 'Philippines', code: 'PH', region: 'Asia', currency: 'PHP' },
  { name: 'Pitcairn', code: 'PN', region: 'Oceania', currency: 'NZD' },
  { name: 'Poland', code: 'PL', region: 'Europe', currency: 'PLN' },
  { name: 'Portugal', code: 'PT', region: 'Europe', currency: 'EUR' },
  { name: 'Puerto Rico', code: 'PR', region: 'Americas', currency: 'USD' },
  { name: 'Qatar', code: 'QA', region: 'Asia', currency: 'QAR' },
  { name: 'Republic of Kosovo', code: 'XK', region: 'Europe', currency: 'EUR' },
  { name: 'Réunion', code: 'RE', region: 'Africa', currency: 'EUR' },
  { name: 'Romania', code: 'RO', region: 'Europe', currency: 'RON' },
  { name: 'Russian Federation', code: 'RU', region: 'Europe', currency: 'RUB' },
  { name: 'Rwanda', code: 'RW', region: 'Africa', currency: 'RWF' },
  { name: 'Saint Barthélemy', code: 'BL', region: 'Americas', currency: 'EUR' },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
    region: 'Africa',
    currency: 'SHP',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    region: 'Americas',
    currency: 'XCD',
  },
  { name: 'Saint Lucia', code: 'LC', region: 'Americas', currency: 'XCD' },
  {
    name: 'Saint Martin (French part)',
    code: 'MF',
    region: 'Americas',
    currency: 'EUR',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    region: 'Americas',
    currency: 'EUR',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    region: 'Americas',
    currency: 'XCD',
  },
  { name: 'Samoa', code: 'WS', region: 'Oceania', currency: 'WST' },
  { name: 'San Marino', code: 'SM', region: 'Europe', currency: 'EUR' },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
    region: 'Africa',
    currency: 'STD',
  },
  { name: 'Saudi Arabia', code: 'SA', region: 'Asia', currency: 'SAR' },
  { name: 'Senegal', code: 'SN', region: 'Africa', currency: 'XOF' },
  { name: 'Serbia', code: 'RS', region: 'Europe', currency: 'RSD' },
  { name: 'Seychelles', code: 'SC', region: 'Africa', currency: 'SCR' },
  { name: 'Sierra Leone', code: 'SL', region: 'Africa', currency: 'SLL' },
  { name: 'Singapore', code: 'SG', region: 'Asia', currency: 'SGD' },
  {
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
    region: 'Americas',
    currency: 'ANG',
  },
  { name: 'Slovakia', code: 'SK', region: 'Europe', currency: 'EUR' },
  { name: 'Slovenia', code: 'SI', region: 'Europe', currency: 'EUR' },
  { name: 'Solomon Islands', code: 'SB', region: 'Oceania', currency: 'SBD' },
  { name: 'Somalia', code: 'SO', region: 'Africa', currency: 'SOS' },
  { name: 'South Africa', code: 'ZA', region: 'Africa', currency: 'ZAR' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    region: 'Americas',
    currency: 'GBP',
  },
  { name: 'Korea (Republic of)', code: 'KR', region: 'Asia', currency: 'KRW' },
  { name: 'South Sudan', code: 'SS', region: 'Africa', currency: 'SSP' },
  { name: 'Spain', code: 'ES', region: 'Europe', currency: 'EUR' },
  { name: 'Sri Lanka', code: 'LK', region: 'Asia', currency: 'LKR' },
  { name: 'Sudan', code: 'SD', region: 'Africa', currency: 'SDG' },
  { name: 'Suriname', code: 'SR', region: 'Americas', currency: 'SRD' },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    region: 'Europe',
    currency: 'NOK',
  },
  { name: 'Swaziland', code: 'SZ', region: 'Africa', currency: 'SZL' },
  { name: 'Sweden', code: 'SE', region: 'Europe', currency: 'SEK' },
  { name: 'Switzerland', code: 'CH', region: 'Europe', currency: 'CHF' },
  { name: 'Syrian Arab Republic', code: 'SY', region: 'Asia', currency: 'SYP' },
  { name: 'Taiwan', code: 'TW', region: 'Asia', currency: 'TWD' },
  { name: 'Tajikistan', code: 'TJ', region: 'Asia', currency: 'TJS' },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
    region: 'Africa',
    currency: 'TZS',
  },
  { name: 'Thailand', code: 'TH', region: 'Asia', currency: 'THB' },
  { name: 'Timor-Leste', code: 'TL', region: 'Asia', currency: 'USD' },
  { name: 'Togo', code: 'TG', region: 'Africa', currency: 'XOF' },
  { name: 'Tokelau', code: 'TK', region: 'Oceania', currency: 'NZD' },
  { name: 'Tonga', code: 'TO', region: 'Oceania', currency: 'TOP' },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    region: 'Americas',
    currency: 'TTD',
  },
  { name: 'Tunisia', code: 'TN', region: 'Africa', currency: 'TND' },
  { name: 'Turkey', code: 'TR', region: 'Asia', currency: 'TRY' },
  { name: 'Turkmenistan', code: 'TM', region: 'Asia', currency: 'TMT' },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    region: 'Americas',
    currency: 'USD',
  },
  { name: 'Tuvalu', code: 'TV', region: 'Oceania', currency: 'AUD' },
  { name: 'Uganda', code: 'UG', region: 'Africa', currency: 'UGX' },
  { name: 'Ukraine', code: 'UA', region: 'Europe', currency: 'UAH' },
  { name: 'United Arab Emirates', code: 'AE', region: 'Asia', currency: 'AED' },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB',
    region: 'Europe',
    currency: 'GBP',
  },
  {
    name: 'United States of America',
    code: 'US',
    region: 'Americas',
    currency: 'USD',
  },
  { name: 'Uruguay', code: 'UY', region: 'Americas', currency: 'UYU' },
  { name: 'Uzbekistan', code: 'UZ', region: 'Asia', currency: 'UZS' },
  { name: 'Vanuatu', code: 'VU', region: 'Oceania', currency: 'VUV' },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    code: 'VE',
    region: 'Americas',
    currency: 'VEF',
  },
  { name: 'Viet Nam', code: 'VN', region: 'Asia', currency: 'VND' },
  { name: 'Wallis and Futuna', code: 'WF', region: 'Oceania', currency: 'XPF' },
  { name: 'Western Sahara', code: 'EH', region: 'Africa', currency: 'MAD' },
  { name: 'Yemen', code: 'YE', region: 'Asia', currency: 'YER' },
  { name: 'Zambia', code: 'ZM', region: 'Africa', currency: 'ZMW' },
  { name: 'Zimbabwe', code: 'ZW', region: 'Africa', currency: 'ZWL' },
];
