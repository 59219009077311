import React from 'react';
import { graphql, navigate, PageProps } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/shared/seo';
import { Header } from '../../components/shared/header/header';
import { Container } from '../../components/shared/container';
import { SeekLegalAdvice } from '../../components/contact/seek-legal-advice';
import Close from '../../assets/svg/close.svg';
import { ToastContainer } from 'react-toastify';

export interface ContactData {
  prismicContactPage: {
    data: {
      big_title: string;
      close_button_text: string;
      small_title: string;
    };
  };
}

const ContactPage: React.FC<PageProps<ContactData>> = ({ data }) => {
  const handleClose = () => {
    navigate(-1);
  };

  const { data: contactData } = data?.prismicContactPage;
  return (
    <Layout>
      <SEO title="Contact" />
      <Header menuHidden />
      <div className="pb-64 mt-24">
        <div className="fixed max-w-body w-full m-auto z-30 md:z-0">
          <div
            className="absolute left-4 -top-16 md:right-5 2xl:-right-40 md:left-auto md:top-8 flex flex-col items-center  p-2 cursor-pointer"
            onClick={() => handleClose()}
          >
            <Close />
            <span className="hidden md:inline header-xs text-greyscale-mediumlight mt-3">
              {contactData.close_button_text}
            </span>
          </div>
        </div>
        <h1>
          <Container>
            <span className="header-xs text-greyscale-mediumlight mt-10 md:mt-0">
              {contactData.small_title}
            </span>
          </Container>
          <span className="block max-w-body m-auto">
            <span className="header-2xl whitespace-nowrap text-greyscale-light mt-0 mb-2 md:mt-10 md:mb-14">
              {contactData.big_title}
            </span>
          </span>
        </h1>
        <Container>
          <SeekLegalAdvice />
        </Container>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    prismicContactPage(lang: { eq: $id }) {
      data {
        big_title
        close_button_text
        small_title
      }
    }
  }
`;

export default ContactPage;
