import cx from 'classnames';
import React, { ChangeEvent, InputHTMLAttributes, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string | JSX.Element;
}

interface RadioGroupProps {
  name: string;
  options: RadioButtonProps[];
  disabled?: boolean;
  horizontal?: boolean;
  className?: string;
  onChange?: RadioButtonProps['onChange'];
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  options,
  className,
  horizontal,
  disabled,
  onChange: onChangeProp,
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('RadioGroup must be inside Form Component');
  }
  const { control } = ctx;

  const handleChange = useCallback(
    (changeFormFunc: (...event: any[]) => void) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        changeFormFunc(event);
        if (onChangeProp) {
          onChangeProp(event);
        }
      },
    [onChangeProp]
  );

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <div
            className={cx(className, {
              'grid grid-flow-col auto-cols-fr gap-y-2': horizontal,
              'flex flex-col gap-3': !horizontal,
            })}
          >
            {options.map((option, i) => (
              <label
                className={cx('flex paragraph text-greyscale-medium', {
                  'cursor-pointer': !disabled,
                  'opacity-80': disabled,
                  'items-center': !option.description,
                })}
                key={i}
                htmlFor={option.value?.toString()}
              >
                <input
                  type="radio"
                  value={option.value}
                  name={name}
                  onChange={handleChange(onChange)}
                  onBlur={onBlur}
                  ref={ref}
                  checked={value === option.value}
                  className="opacity-0 w-0 h-0"
                  disabled={disabled}
                  id={option.value?.toString()}
                />
                <span
                  className={cx('mr-3 block w-5 h-5 rounded-full bg-white', {
                    'border-4 border-ecit-green': value === option.value,
                    'border border-greyscale-mediumlight':
                      value !== option.value,
                    'bg-greyscale-fairlylight': disabled,
                  })}
                />
                <span className="flex flex-col">
                  <span
                    className={cx({
                      'text-greyscale-mediumlight': value !== option.value,
                    })}
                  >
                    {option.label || option.value}
                  </span>
                  {option.description}
                </span>
              </label>
            ))}
          </div>
        )}
      />
    </>
  );
};
