import SearchIcon from '../../../assets/svg/search.svg';
import XCircleIcon from '../../../assets/svg/x-circle.svg';
import { useDebouncedEffect } from '../../../hooks/use-debounce-effect';
import cx from 'classnames';
import React, {
  ChangeEvent,
  useState,
  useRef,
  useCallback,
  HTMLProps,
} from 'react';
import { useClickOutside } from '../../../hooks/use-click-outside';
import { getInputClass } from '../input/input';
import { Loader } from '../loader';

export default interface SearchInputOption {
  id: string | number;
  name: string;
  description: string;
}

export interface SearchInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'name' | 'onChange'> {
  name: string;
  options?: SearchInputOption[];
  onChange: (value?: string) => void;
  onOptionClick?: (option: SearchInputOption) => void;
  loading?: boolean;
  recentText?: string;
  noResultsText?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  options,
  onOptionClick,
  onChange: onChangeProp,
  onBlur,
  loading,
  recentText,
  noResultsText,
  ...props
}) => {
  const [value, setValue] = React.useState<string>();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [isFocused, setIsFocused] = useState(false);

  const containerElem = useRef<HTMLDivElement>(null);

  const clickOutsideCallback = useCallback(() => {
    setIsFocused(false);
  }, []);

  useClickOutside(clickOutsideCallback, [containerElem.current]);

  useDebouncedEffect(() => onChangeProp(value), [value], 500);

  const handleOnChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      if (!value || value.length > 2) setValue(value);
      setInputValue(value);
    },
    []
  );

  const clearValue = React.useCallback(() => {
    setValue('');
    setInputValue('');
    onChangeProp('');
  }, [onChangeProp]);

  const handleOption = useCallback(
    (option: SearchInputOption) => {
      onOptionClick?.(option);
      setIsFocused(false);
    },
    [onOptionClick]
  );
  return (
    <div ref={containerElem} className="relative w-full">
      <div className="relative">
        <div className="flex absolute inset-y-0 left-0 items-center pl-1 pointer-events-none">
          <SearchIcon className="w-5 h-5 text-ecit-green" />
        </div>
        {inputValue && (
          <div
            className="flex absolute inset-y-0 left-searchInputX items-center pointer-events-auto cursor-pointer"
            onClick={clearValue}
          >
            <XCircleIcon className="w-6 h-6 text-greyscale-mediumlight" />
          </div>
        )}
        <input
          onKeyDown={e => {
            if (e.key === 'Enter' && options?.length) {
              handleOption(options[0]);
            }
          }}
          className={cx('pl-8', getInputClass('', true, props.disabled))}
          {...props}
          aria-label={props.name}
          value={inputValue}
          onChange={handleOnChange}
          onBlur={onBlur}
          onFocus={() => setIsFocused(true)}
          placeholder={props.placeholder}
        />
      </div>

      {isFocused && (
        <div
          className={cx(
            'z-10 w-full absolute bg-white max-h-72 overflow-y-auto ',
            {
              'border-b-2 border-ecit-green pb-2':
                options?.length || loading || value?.length,
            }
          )}
        >
          {loading ? (
            <span className="flex items-center justify-center p-4">
              <Loader />
            </span>
          ) : options?.length ? (
            <p className="p-2 pt-4 text-greyscale-mediumlight text-base font-medium">
              {recentText || 'Recent'}
            </p>
          ) : !value?.length ? null : (
            <p className="p-4 pb-2 text-greyscale-mediumlight text-base font-medium">
              {noResultsText || 'No results'}
            </p>
          )}
          <ul className="space-y-3">
            {options?.map(option => {
              return (
                <li
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleOption(option);
                    }
                  }}
                  tabIndex={0}
                  onClick={() => handleOption(option)}
                  className="hover:bg-white p-2 flex flex-row items-center cursor-pointer text-ecit-blue hover:text-ecit-green"
                  key={option.id}
                >
                  <SearchIcon className="text-greyscale-mediumlight mr-2 w-3" />
                  <p className="flex flex-col">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: (option.name || '').replace(
                          new RegExp(inputValue || '', 'gi'),
                          match =>
                            `<span class="text-ecit-green">${match}</span>` as any
                        ),
                      }}
                      className="text-base font-medium"
                    />
                    <span className="text-sm">{option.description}</span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
