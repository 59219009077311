import { useState, useEffect } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */

export const useDebouncedEffect = (
  fn: () => void,
  deps: any[],
  delay: number
) => {
  const [skip, setSkip] = useState(true);
  useEffect(() => {
    let handler: any;

    if (!skip) handler = setTimeout(() => fn(), delay);

    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);

  useEffect(() => {
    setSkip(false);
  }, [...(deps || [])]);
};
