import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Enheter } from '../../contact/company-brreg.type';
import SearchInputOption, {
  SearchInput,
  SearchInputProps,
} from './search-input';

type SearchOption = SearchInputOption & {
  type: string;
  address: {
    address1: string;
    city: string;
    postalCode: string;
    countryCode: string;
  };
};

export interface BrregSearchInputProps extends Partial<SearchInputProps> {
  onApplySearch: (option: SearchInputOption) => void;
  errorText?: string;
}

export const BrregSearchInput: React.FC<BrregSearchInputProps> = ({
  onApplySearch,
  errorText,
  ...props
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);

  const onSearchChange = useCallback(async (value?: string) => {
    if (value) {
      setIsSearching(true);
      try {
        const orgNumber = Number(value.replace(/\s/g, ''));
        if (!isNaN(orgNumber) && orgNumber?.toString().length === 9) {
          // search by org number
          const response = await axios({
            url: `${'https://data.brreg.no/enhetsregisteret/api/enheter'}/${orgNumber}`,
          });
          const address = response.data.forretningsadresse.adresse;
          setSearchOptions([
            {
              name: response.data.navn,
              type: response.data.organisasjonsform.beskrivelse,
              address: {
                address1: address.join(' '),
                city: response.data.forretningsadresse.poststed,
                postalCode: response.data.forretningsadresse.postnummer,
                countryCode: response.data.forretningsadresse.landkode,
              },
              id: response.data.organisasjonsnummer,
              description: response.data.organisasjonsnummer,
            },
          ]);
        } else {
          // search by name
          const response = await axios({
            url: `${'https://data.brreg.no/enhetsregisteret/api/enheter'}?navn=${window.encodeURIComponent(
              value
            )}`,
          });
          setSearchOptions(
            response.data._embedded?.enheter
              ?.sort((a: Enheter, b: Enheter) => {
                return a.navn.match(value.toUpperCase()) &&
                  !b.navn.match(value.toUpperCase())
                  ? -1
                  : !a.navn.match(value.toUpperCase()) &&
                    b.navn.match(value.toUpperCase())
                  ? 1
                  : 0;
              })
              ?.slice(0, 5)
              .map((elem: Enheter) => {
                const address = elem.forretningsadresse?.adresse || [];

                return {
                  name: elem.navn,
                  type: elem.organisasjonsform.beskrivelse,
                  industryCode: [
                    elem.naeringskode1?.kode,
                    elem.naeringskode2?.kode,
                    elem.naeringskode3?.kode,
                    elem.naeringskode4?.kode,
                    elem.naeringskode5?.kode,
                  ].filter(Boolean),
                  sectorCode: elem.institusjonellSektorkode?.kode,
                  address: {
                    address1: address.join(' '),
                    city: elem.forretningsadresse?.poststed,
                    postalCode: elem.forretningsadresse?.postnummer,
                    countryCode: elem.forretningsadresse?.landkode,
                  },
                  id: elem.organisasjonsnummer,
                  description: elem.organisasjonsnummer,
                };
              })
          );
        }
      } catch {
        toast.error(errorText || 'Company not found');
      } finally {
        setIsSearching(false);
      }
    }
  }, []);

  return (
    <SearchInput
      loading={isSearching}
      options={searchOptions}
      onOptionClick={onApplySearch}
      name="search-company"
      onChange={onSearchChange}
      {...props}
    />
  );
};
