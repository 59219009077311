import React from 'react';
import cx from 'classnames';
import { components } from 'react-select';
import XCircleIcon from '../../../assets/svg/x-circle.svg';
import { getInputClass, Star } from '../input/input';

export const selectComponentsStyles = {
  container: () => ({}),
  control: () => ({}),
  option: () => ({}),
  placeholder: (props: any) => ({
    ...props,
    color: undefined,
  }),
  groupHeading: (props: any) => ({
    ...props,
    color: undefined,
  }),
  menu: (props: any) => ({
    ...props,
    borderRadius: 0,
    margin: 0,
    boxShadow: undefined,
  }),
  menuList: (props: any) => ({
    ...props,
    padding: 0,
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: () => ({}),
  clearIndicator: (props: any) => ({
    ...props,
    boxSizing: 'content-box' as const,
  }),
  multiValue: () => ({}),
  multiValueContainer: () => ({}),
  multiValueLabel: () => ({}),
  multiValueRemove: () => ({}),
  valueContainer: (props: any) => ({
    ...props,
    padding: 0,
  }),
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator
    {...props}
    className="flex m-2 w-5 text-greyscale-dark"
  />
);

const ClearIndicator = (props: any) => (
  <components.ClearIndicator {...props} className="w-4">
    <XCircleIcon className="w-5 h-5 text-greyscale-mediumlight" />
  </components.ClearIndicator>
);

const Placeholder = (props: any) => (
  <components.Placeholder
    {...props}
    className={cx(
      'text-base font-normal',
      props.isFocused ? 'text-greyscale-medium' : 'text-greyscale-mediumlight'
    )}
  />
);

const Control = ({ hasError, required, children, ...props }: any) => (
  <components.Control
    {...props}
    className={cx(
      'flex relative',
      getInputClass('pr-0', true, props.isDisabled, false, true),
      {
        'text-greyscale-mediumlight bg-greyscale-fairlylight': props.isDisabled,
        'bg-red-50 text-red-900 placeholder:text-red-700 focus:ring-red-500 focus:border-red-500':
          hasError,
        'text-greyscale-mediumlight': !hasError && !props.isFocused,
        'bg-white': !hasError,
        'transition-border border-ecit-green': props.isFocused && !hasError,
      }
    )}
  >
    {required && <Star className="text-base font-normal" />}
    {children}
  </components.Control>
);

const Input = ({ type, ...props }: any) => (
  <components.Input
    className={
      !props.selectProps.menuIsOpen &&
      !props.isMulti &&
      !props.value &&
      props.hasValue &&
      'pl-9'
    }
    {...props}
  />
);

const Option = (props: any) => (
  <components.Option
    {...props}
    className={cx(
      'text-base bg-white hover:text-ecit-green cursor-pointer text-greyscale-dark p-3',
      props.isFocused && 'text-ecit-green',
      props.data.hasTabs && 'pl-8'
    )}
  >
    <span className="flex gap-3 items-center">
      {props.data.optionLabel || props.children}
    </span>
    {props.data.description && (
      <span className="pt-0.5 text-sm text-greyscale-light">
        {props.data.description}
      </span>
    )}
  </components.Option>
);

const ValueContainer = (props: any) => (
  <components.ValueContainer {...props} className="gap-2" />
);

const Group = (props: any) => (
  <components.Group {...props} className="bg-white" />
);

const GroupHeading = (props: any) => (
  <components.GroupHeading
    {...props}
    className="text-greyscale-mediumlight font-semibold"
  />
);

const SingleValue = (props: any) => (
  <components.SingleValue {...props}>
    <span
      className={cx('flex gap-3 items-center', {
        'text-greyscale-mediumlight': props.selectProps.menuIsOpen,
      })}
    >
      {props.children}
    </span>
  </components.SingleValue>
);

const Menu = (props: any) => (
  <components.Menu {...props} className="border-b-2 border-ecit-green" />
);

const NoOptionsMessage = (props: any) => (
  <components.NoOptionsMessage
    {...props}
    className="text-base text-greyscale-mediumlight bg-white"
  />
);

const MultiValue = (props: any) => (
  <components.MultiValue
    {...props}
    className={cx('flex gap-2 px-2 py-1 text-base', {
      'bg-white text-ecit-green hover:bg-greyscale-fairlylight':
        !props.isDisabled,
      'bg-greyscale-fairlylight text-greyscale-mediumlight': props.isDisabled,
    })}
    components={{
      Container: components.MultiValueContainer,
      Label: p => (
        <components.MultiValueLabel {...p} innerProps={{ className: '' }} />
      ),
      Remove: p =>
        !props.isDisabled && (
          <components.MultiValueRemove
            {...p}
            innerProps={{ ...p.innerProps, className: '' }}
          />
        ),
    }}
  />
);

export const multiSelectComponents = {
  ClearIndicator,
  Control,
  DropdownIndicator,
  Input,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

export const selectComponents = {
  ClearIndicator,
  Control,
  DropdownIndicator,
  Group,
  GroupHeading,
  Input,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
};
