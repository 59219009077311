import { useCallback, useEffect } from 'react';

export function useClickOutside(
  callback: () => void,
  elements?: (HTMLElement | null)[]
) {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        elements?.every(
          (element) => element && !element.contains(event.target as Element)
        )
      ) {
        callback();
      }
    },
    [callback, elements]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
}
